import { AUTH_EXITO, AUTH_CERRAR } from "../aciones/type";

const initialState = {
  usuario: ""
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_EXITO:
      return { ...state, usuario: payload };

    case AUTH_CERRAR:
      return { ...state, usuario: "" };
    default:
      return state;
  }
};
