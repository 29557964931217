import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import { withRouter } from "react-router-dom";
const MenunEstudiante = ({ history }) => {
  return (
    <div>
      <ListItem button onClick={() => history.push("/home/estudiante")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push("/home/estudiante/publicaciones")}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Publicaciones" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push("/home/estudiante/curriculo")}
      >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Curriculum" />
      </ListItem>
    </div>
  );
};

export default withRouter(MenunEstudiante);
