import {
  OBTENER_VACANTES,
  CREAR_VACANTES,
  ELIMINAR_VACANTES,
  OBTENERHOJAVIDA,
  OBTENERCANDIDATOS
} from "./type";
import { auth, db, storage } from "../../firebase";
import { mensaje } from "./mensajes";

export const obtenerVacantes = () => async dispatch => {
  try {
    await auth.onAuthStateChanged(user => {
      if (user) {
        let citiesRef = db.collection("vacante");
        citiesRef
          .where("id_autor", "==", user.uid)
          .get()
          .then(snapshot => {
            const docs = [];
            if (snapshot.empty) {
              dispatch(mensaje("No hay Publicaciones ", "warning"));
              return;
            }
            snapshot.forEach(doc => {
              const data = doc.data();
              docs.push({ ...data, id: doc.id });
            });
            dispatch({
              type: OBTENER_VACANTES,
              payload: docs
            });
          });
      }
    });
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const ObtenerCandidatos = ({ id }) => dispatch => {
  try {
    let citiesRef = db.collection(id);
    citiesRef.onSnapshot(snapshot => {
      const docs = [];
      if (snapshot.empty) {
        dispatch({
          type: OBTENERCANDIDATOS,
          payload: []
        });
      }
      snapshot.forEach(doc => {
        const data = doc.data();
        docs.push({ ...data, id: doc.id });
      });
      dispatch({
        type: OBTENERCANDIDATOS,
        payload: docs
      });
      dispatch(mensaje("Candidatos Cargado Exitosamente.......", "success"));
    });
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const cargarHojaVida = ({ id, curriculum }) => async dispatch => {
  try {
    if (curriculum.type === "application/pdf") {
      await storage.ref(`/pdf/${id}`).put(curriculum);
      dispatch(mensaje("Archivo cargado Exitosamente.......", "success"));
    }
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const obtenerHojadeVida = ({ id }) => async dispatch => {
  try {
    await storage
      .ref(`/pdf/${id}`)
      .getDownloadURL()
      .then(url => {
        dispatch({
          type: OBTENERHOJAVIDA,
          payload: url
        });
      });
  } catch (err) {
    dispatch(mensaje("Ingrese una hoja de vida ", "error"));
  }
};

export const crearVacantes = ({ data }) => async dispatch => {
  try {
    await db
      .collection("vacante")
      .doc()
      .set(data);
    dispatch({ type: CREAR_VACANTES, payload: [data] });
    dispatch(mensaje("Vacante Creada Exitosamente.......", "success"));
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const mandarSolicitudPostulante = data => dispatch => {
  const { id, uid } = data;
  try {
    db.collection(id)
      .doc(uid)
      .set(data);
    dispatch(mensaje("solicitud enviada correctamente .......", "success"));
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const ObtenerVacantesEstudiante = () => dispatch => {
  try {
    let citiesRef = db.collection("vacante");
    citiesRef.onSnapshot(snapshot => {
      const docs = [];
      if (snapshot.empty) {
        dispatch({
          type: OBTENER_VACANTES,
          payload: []
        });
      }
      snapshot.forEach(doc => {
        const data = doc.data();
        docs.push({ ...data, id: doc.id });
      });
      dispatch({
        type: OBTENER_VACANTES,
        payload: docs
      });
      dispatch(mensaje("Vacante Cargado Exitosamente.......", "success"));
    });
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const updateVacantes = ({ data, id }) => async dispatch => {
  try {
    await db
      .collection("vacante")
      .doc(id)
      .update(data);
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const deleteVacantes = ({ elemento }) => async dispatch => {
  try {
   
    let query = db.collection(elemento.id);
    query.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        doc.ref.delete();
      });
      db.collection("vacante")
        .doc(elemento.id)
        .delete();
    });

    dispatch({ type: ELIMINAR_VACANTES, payload: elemento });
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};
