import { combineReducers } from "redux";
import login from "./login";
import crud_empresa from "./crud_empresa";
import mensaje from "./mensaje";
import util from "./util";

export default combineReducers({
  mensaje,
  login,
  crud_empresa,
  util
});
