import { MENSAJE_BORRAR, MENSAJE_EXITO } from "../aciones/type";
const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MENSAJE_EXITO:
      return [...state, payload];

    case MENSAJE_BORRAR:
      return state.filter(alert => alert.id !== payload);

    default:
      return state;
  }
};
