export const AUTH_EXITO = "AUTH_EXITO";
export const AUTH_CERRAR = "AUTH_CERRAR";

export const OBTENER_VACANTES = "OBTENER_VACANTES";
export const CREAR_VACANTES = "CREAR_VACANTES";
export const ELIMINAR_VACANTES = "ELIMINAR_VACANTES";
export const OBTENERHOJAVIDA = 'OBTENERHOJAVIDA'
export const OBTENERCANDIDATOS = 'OBTENERCANDIDATOS'


export const MENSAJE_EXITO = "MENSAJE_EXITO";
export const MENSAJE_BORRAR = "MENSAJE_BORRAR";
