import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import {
  cargarHojaVida,
  obtenerHojadeVida
} from "../../redux/aciones/crud_empresa";
import { mensaje } from "../../redux/aciones/mensajes";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important" // override inline-style
    }
  }
})(TextField);

const CargarPdf = ({
  cargarHojaVida,
  usuario,
  mensaje,
  obtenerHojadeVida,
  pdf
}) => {
  const classes = useStyles();

  const [stateObjeto, setstateObjeto] = React.useState("");

  const verificar = stateObjeto === "";

  const onChange = e => {
    const curriculum = e.target.files[0];
    if (curriculum.type === "application/pdf") {
      setstateObjeto(curriculum);
    } else {
      setstateObjeto("");
      mensaje("El archivo debe se en formato PDF", "error");
    }
  };

  const CargarHojaVida = () => {
    cargarHojaVida({ id: usuario.uid, curriculum: stateObjeto });
    setstateObjeto("");
  };
  React.useEffect(() => {
    obtenerHojadeVida({ id: usuario.uid });
  }, [obtenerHojadeVida, usuario.uid]);

  return (
    <React.Fragment>
      <ValidationTextField
        className={classes.margin}
        required
        variant="outlined"
        type="file"
        name="Curriculum"
        onChange={e => onChange(e)}
      />
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Button
            disabled={verificar}
            onClick={CargarHojaVida}
            variant="contained"
            color="secondary"
          >
            Cargar Hoja de vida
          </Button>
        </Grid>
      </Grid>
      {pdf ? (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <div>
              <embed
                src={pdf}
                type="application/pdf"
                width="600"
                height="400"
              ></embed>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};
const mapStateToProps = state => ({
  usuario: state.login.usuario,
  pdf: state.util.url
});

export default connect(mapStateToProps, {
  cargarHojaVida,
  mensaje,
  obtenerHojadeVida
})(CargarPdf);
