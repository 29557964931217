import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBVfANSP8kxHeggOX91jvxISpF2-JiEg74",
  authDomain: "small-talk-1-koxpcj.firebaseapp.com",
  databaseURL: "https://small-talk-1-koxpcj.firebaseio.com",
  projectId: "small-talk-1-koxpcj",
  storageBucket: "small-talk-1-koxpcj.appspot.com",
  messagingSenderId: "1026574064014",
  appId: "1:1026574064014:web:21b3c22de2f8f8eb90acdf"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
