import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import Login from "./layout/Login";
import Registro from "./layout/Registro";
import Mensaje from "./layout/Mensaje";
import PrivateRouter from "./components/Private_Router/Private_Router";
import Home from "./layout/Home";
import HomeFormulario from "./components/Home/HomeFormulario";
import HomeReportes from "./components/Home/HomeReportes";
import HomeEstudiante from "./layout/HomeEstudiante";
import HomeEstudianteCurriculo from "./components/homeEstudiante/HomeEstudianteCurriculo";
import HomeEstudianteVacantes from "./components/homeEstudiante/HomeEstudianteVacantes";
import HomeCandidados from "./components/Home/HomeCandidados";
import ReanviarEmail from "./components/ReviarEmail/ReanviarEmail";
import HomeAdministrador from "./layout/HomeAdministrador";
import HomeReportesAdministrador from "./components/homeAdministrador/HomeReportesAdministrador";
import HomeFormularioAdministrador from "./components/homeAdministrador/HomeFormularioAdministrador";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Mensaje />
        <Switch>
          <PrivateRouter exact type="publico" path="/login" component={Login} />
          <PrivateRouter
            exact
            type="publico"
            path="/registro"
            component={Registro}
          />
          <PrivateRouter exact type="privado" path="/home" component={Home} />
          <PrivateRouter
            exact
            type="privado"
            path="/home/formulario"
            component={HomeFormulario}
          />
          <PrivateRouter
            exact
            type="privado"
            path="/home/reporte"
            component={HomeReportes}
          />
          <PrivateRouter
            exact
            type="privado"
            path="/home/estudiante"
            component={HomeEstudiante}
          />

          <PrivateRouter
            exact
            type="privado"
            path="/home/estudiante/publicaciones"
            component={HomeEstudianteVacantes}
          />
          <PrivateRouter
            exact
            type="privado"
            path="/home/estudiante/curriculo"
            component={HomeEstudianteCurriculo}
          />
          <PrivateRouter
            exact
            type="privado"
            path="/candidato/:id"
            component={HomeCandidados}
          />

          <PrivateRouter
            exact
            type="privado"
            path="/enviarEmail"
            component={ReanviarEmail}
          />
          <PrivateRouter
            exact
            type="privado"
            path="/home/administrador"
            component={HomeAdministrador}
          />

          <PrivateRouter
            exact
            type="privado"
            path="/home/administrador/formulario"
            component={HomeFormularioAdministrador}
          />
          <PrivateRouter
            exact
            type="privado"
            path="/home/administrador/reporte"
            component={HomeReportesAdministrador}
          />

          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
