import {
  OBTENER_VACANTES,
  CREAR_VACANTES,
  ELIMINAR_VACANTES
} from "../aciones/type";
const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OBTENER_VACANTES:
      return state=payload;
    case CREAR_VACANTES:
      return [...state.concat(payload)];
    case ELIMINAR_VACANTES:
      return [...state.filter( e => e.id !== payload.id )];

    default:
      return state;
  }
};
