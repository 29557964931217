import { auth, db } from "../../firebase";
import { AUTH_EXITO, AUTH_CERRAR } from "./type";
import { mensaje } from "./mensajes";

export const login = ({ email, password }) => async dispatch => {
  try {
    await auth.signInWithEmailAndPassword(email, password);

    obtenerUsuario();
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const registro = ({
  usuario,
  email,
  password,
  rol
}) => async dispatch => {
  try {
    const crear_usuario = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    await db
      .collection("usuario")
      .doc()
      .set({
        email: crear_usuario.user.email,
        id: crear_usuario.user.uid,
        usuario,
        rol
      });
    await auth.currentUser.sendEmailVerification();

    obtenerUsuario();
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const obtenerUsuario = () => async dispatch => {
  try {
    await auth.onAuthStateChanged(user => {
      if (user) {
        let citiesRef = db.collection("usuario");
        citiesRef
          .where("id", "==", user.uid)
          .get()
          .then(snapshot => {
            let data;
            if (snapshot.empty) {
              console.log("No matching documents.");
              return;
            }
            snapshot.forEach(doc => {
              data = doc.data();
            });
            dispatch({
              type: AUTH_EXITO,
              payload: {
                uid: user.uid,
                displayName: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                emailVerified: user.emailVerified,
                rol: data.rol,
                usuario: data.usuario
              }
            });
          })
          .catch(err => {
            console.log("Error getting documents", err);
          });
      }
    });
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};

export const renviarCoreoValidar = () => async dispatch => {
  try {
    await auth.currentUser.sendEmailVerification(
      () => dispatch(mensaje("Dirijase al Coreo electronico", "error")),
      obtenerUsuario()
    );
  } catch (err) {
    dispatch(
      mensaje(
        "Hemos bloqueado todas las solicitudes de este dispositivo debido a una actividad inusual. Intenta nuevamente más tarde.",
        "error"
      )
    );
  }
};

export const salirUsuario = () => async dispatch => {
  try {
    await auth.signOut();
    dispatch({ type: AUTH_CERRAR });
  } catch (err) {
    dispatch(mensaje(err.message, "error"));
  }
};
