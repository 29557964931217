import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import { registro, obtenerUsuario } from "../redux/aciones/login";
import { Link as Enlace } from "react-router-dom";
import loginImagenes from "../recursos/login.jpg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://itspelileo.edu.ec/">
        ISTP
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: `url(${loginImagenes})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const Registro = ({ registro, obtenerUsuario }) => {
  React.useEffect(() => {
    obtenerUsuario();
  }, [obtenerUsuario]);

  const classes = useStyles();

  const [stateObjeto, setstateObjeto] = React.useState({
    usuario: "",
    email: "",
    password: "",
    password1: "",
    rol: "ESTUDIANTE"
  });

  const { usuario, email, password, password1, rol } = stateObjeto;

  const onChange = e => {
    setstateObjeto({ ...stateObjeto, [e.target.name]: e.target.value });
  };

  const verificar =
    usuario === "" || email === "" || password === "" || password1 === "";

  const registarUsuario = e => {
    e.preventDefault();

    if (usuario === "" || email === "" || password === "" || password1 === "") {
      console.log("1");
    } else {
      if (password === password1) {
        registro({ usuario, email, password, rol });
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registrate
          </Typography>
          <form className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="usuario"
              label="Nombre de usuario "
              name="usuario"
              autoComplete="usuario"
              type="text"
              autoFocus
              onChange={e => onChange(e)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Dirección de correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => onChange(e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => onChange(e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password1"
              label="Password"
              type="password"
              id="password1"
              autoComplete="current-password"
              onChange={e => onChange(e)}
            />

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">
                Rol
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={rol}
                name="rol"
                onChange={e => onChange(e)}
              >
                <MenuItem value="">
                  <em>SELECIONE SU ROL</em>
                </MenuItem>
                <MenuItem value="EMPRESA">EMPRESA</MenuItem>
                <MenuItem value="ESTUDIANTE">ESTUDIANTE</MenuItem>
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={e => registarUsuario(e)}
              disabled={verificar}
            >
              Registrarse
            </Button>
            <Grid container>
              <Grid item xs>
                <Enlace to="#">{" ¿Se te olvidó tu contraseña?"}</Enlace>
              </Grid>
              <Grid item>
                <Enlace to="/login">{"¿Ya tienes una cuenta? Ingresa"}</Enlace>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default connect(null, { registro, obtenerUsuario })(Registro);
