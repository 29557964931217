import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const Private_Router = props => {
  const { auth, type, ...rest } = props;

  if (type === "privado" && auth === "") {
    return <Redirect to="/login" />;
  } else if (
    type === "publico" &&
    auth !== "" &&
    auth.rol === "EMPRESA" &&
    auth.emailVerified
  ) {
    return <Redirect to="/home" />;
  } else if (
    type === "publico" &&
    auth !== "" &&
    auth.rol === "ESTUDIANTE" &&
    auth.emailVerified
  ) {
    return <Redirect to="/home/estudiante" />;
  } else if (
    type === "publico" &&
    auth !== "" &&
    auth.rol === "ADMINISTRADOR" &&
    auth.emailVerified
  ) {
    return <Redirect to="/home/administrador" />;
  } else if (type === "publico" && auth !== "" && !auth.emailVerified) {
    return <Redirect to="/enviarEmail" />;
  }

  return <Route {...rest} />;
};

const mapStateToProps = state => ({
  auth: state.login.usuario
});

export default connect(mapStateToProps, null)(Private_Router);
