import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import PeopleIcon from "@material-ui/icons/People";
import { connect } from "react-redux";
import { deleteVacantes } from "../../redux/aciones/crud_empresa";



const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3)
  },

  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Resultado = ({ vacantes_item, deleteVacantes,history }) => {
  
  const EliminarVacante = ({ elemento }) => {
   
    deleteVacantes({ elemento });
  };

  const classes = useStyles();
  React.useEffect(() => {}, [vacantes_item]);
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>empresa</TableCell>
            <TableCell>contrato</TableCell>
            <TableCell>ubicacion</TableCell>
            <TableCell>vacante</TableCell>
            <TableCell>descripcion</TableCell>
            <TableCell align="right">acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vacantes_item
            ? vacantes_item.map(elemento => {
                const {
                  id,
                  empresa,
                  contrato,
                  ubicacion,
                  vacante,
                  descripcion
                } = elemento;
                return (
                  
                  <TableRow key={id}>
                    <TableCell>{empresa}</TableCell>
                    <TableCell>{contrato}</TableCell>
                    <TableCell>{ubicacion}</TableCell>
                    <TableCell>{vacante}</TableCell>
                    <TableCell>{descripcion}</TableCell>
                    <TableCell align="right">
                      <div className={classes.root}>
                        <Fab color="primary" aria-label="add">
                          <PeopleIcon onClick={()=>history.push(`/candidato/${id}`)} />
                        </Fab>
                        <Fab
                          color="secondary"
                          aria-label="edit"
                          onClick={() => EliminarVacante({ elemento })}
                        >
                          <DeleteIcon />
                        </Fab>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table> 
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  vacantes_item: state.crud_empresa
});

export default connect(mapStateToProps, {
  deleteVacantes
})(withRouter(Resultado));
