import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";

import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import { withRouter } from "react-router-dom";

const Menun = ({ history }) => {
  return (
    <div>
      <ListItem button onClick={() => history.push("/home")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem button onClick={() => history.push("/home/formulario")}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Nuevo" />
      </ListItem>

      <ListItem button onClick={() => history.push("/home/reporte")}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Publicaciones" />
      </ListItem>
    </div>
  );
};

export default withRouter(Menun);
