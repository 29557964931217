import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Grid, FormControl, Button } from "@material-ui/core";
import uuid from "uuid";
import { connect } from "react-redux";
import { crearVacantes } from "../../redux/aciones/crud_empresa";
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important" // override inline-style
    }
  }
})(TextField);

const style = {
  maxHeight: "75px",
  minHeight: "38px",
  padding: "9px",
  boxSizing: "border-box",
  fontSize: "15px"
};

const Formulario = ({ crearVacantes, usuario }) => {
  const classes = useStyles();

  const [stateObjeto, setstateObjeto] = React.useState({
    empresa: "",
    vacante: "",
    ubicacion: "",
    contrato: "",
    descripcion: ""
  });

  const { empresa, vacante, ubicacion, contrato, descripcion } = stateObjeto;

  const verificar =
    empresa === "" ||
    vacante === "" ||
    ubicacion === "" ||
    contrato === "" ||
    descripcion === "";

  const onChange = e => {
    setstateObjeto({ ...stateObjeto, [e.target.name]: e.target.value });
  };

  const CrearVacanteFormulario = () => {
    const { uid } = usuario;
    const data = {
      id: uuid.v4(),
      id_autor: uid,
      empresa,
      vacante,
      ubicacion,
      contrato,
      descripcion,
      candidatos: []
    };
    Swal.fire({
      title: "Esta Seguro?",
      text: "Que desea a crear la postulacion!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Confirmar!",
      cancelButtonText:'Cancelar'
    }).then(result => {
      if (result.value) {
        Swal.fire("Creado!", "La postulacion se creo correctamente.", "success");
        crearVacantes({ data });
        setstateObjeto({
          empresa: "",
          vacante: "",
          ubicacion: "",
          contrato: "",
          descripcion: ""
        });
      }
    });
  };

  return (
    <form className={classes.root} noValidate>
      <FormControl fullWidth className={classes.margin}>
        <ValidationTextField
          className={classes.margin}
          label="Ingrese el nombre de la empresa "
          required
          variant="outlined"
          name="empresa"
          onChange={e => onChange(e)}
          value={empresa}
        />
        <ValidationTextField
          className={classes.margin}
          label="Ingrese la vacante"
          required
          variant="outlined"
          name="vacante"
          onChange={e => onChange(e)}
          value={vacante}
        />
        <ValidationTextField
          className={classes.margin}
          label="Ingrese la Ubicacion"
          required
          variant="outlined"
          name="ubicacion"
          onChange={e => onChange(e)}
          value={ubicacion}
        />
      </FormControl>

      <FormControl fullWidth className={classes.margin}>
        <ValidationTextField
          className={classes.margin}
          label="Ingrese tipo de contrato "
          required
          variant="outlined"
          name="contrato"
          onChange={e => onChange(e)}
          value={contrato}
        />

        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <TextareaAutosize
              style={style}
              aria-label="empty textarea"
              placeholder="Ingrese una descripcion "
              name="descripcion"
              onChange={e => onChange(e)}
              value={descripcion}
            />
          </Grid>
        </Grid>
      </FormControl>

      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Button
            disabled={verificar}
            onClick={CrearVacanteFormulario}
            variant="contained"
            color="secondary"
          >
            Crear Nueva Vacante
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = state => ({
  usuario: state.login.usuario
});

export default connect(mapStateToProps, { crearVacantes })(Formulario);
