import { OBTENERHOJAVIDA, OBTENERCANDIDATOS } from "../aciones/type";

const initialState = {
  url: "",
  candidatos: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OBTENERHOJAVIDA:
      return { ...state, url: payload };

    case OBTENERCANDIDATOS:
      return { ...state, candidatos: payload };
    default:
      return state;
  }
};
